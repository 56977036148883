<template>
  <div class="container py-3">
    <div class="row no-gutters">
      <div
        class="col-12 d-none"
        :class="{
          'col-md-4': zones.length > 29,
          'col-12': zones.length <= 9,
        }"
      >
        <div class="row">
          <div
            class="py-3 col-4 col-md-3"
            v-for="(zone, i) in getZones"
            :key="i"
            style="position: relative"
            :class="getClass(zone)"
          >
            <span class="font-weight-bold text-uppercase">{{ zone.name }}</span>
            <span
              class="ml-2 badge badge-danger text"
              style="position: absolute; top: 5px; right: 5px"
              >${{ formatNumber(zone.value) }}</span
            >
          </div>
        </div>
      </div>
      <div
        class="col-12 col-md-4 px-1 custom_scroll overflow-auto"
        style="max-height: 60vh"
      >
        <div class="row">
          <div class="col-12" v-for="(zone, i) in getZones" :key="i">
            <div
              class="badge text-wrap py-2 mb-1 d-inline-block w-100"
              style="min-width: 100%"
              :class="getClass(zone)"
            >
              <h6 class="font-weight-bold text-uppercase mb-1">
                {{ zone.name }}

                <span class="ml-2 badge badge-danger text" v-if="zone.value">
                  ${{ formatNumber(zone.value) }}
                </span>
                <span
                  class="ml-2 badge badge-light text"
                  v-if="zone.type == 'km' && zone.kms"
                  >Desde ${{ formatNumber(zone.kms[0].value) }}</span
                >
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row no-gutters">
          <div class="col px-1">
            <gmap-map
              :center="point"
              :zoom="11.8"
              style="width: 100%; min-height: 60vh"
            >
              <component
                :is="path.type == 'zone' ? 'gmap-polygon' : 'gmap-circle'"
                v-for="(path, i) in zones"
                :key="`path-${i}`"
                :paths="path.paths"
                :center="path.center || point"
                :radius="path.radius"
                :options="{
                  strokeColor: path.color,
                }"
              />
              <!-- <GmapMarker
            v-for="(path, i) in zones"
            :key="`marker-${i}`"
            :icon="null"
            :label="`${path.name}`"
            :position="getCenterMarker(path)"
          ></GmapMarker> -->
            </gmap-map>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCenter } from "geolib";
export default {
  data() {
    return {
      point: { lat: -36.8001887, lng: -73.004514 },
    };
  },
  mounted() {
    this.centerMap();
  },
  methods: {
    getCenterMarker(zone) {
      let zones = [];
      let z = zone.paths.map((z) => ({ latitude: z.lat, longitude: z.lng }));
      zones.push(...z);
      let center = getCenter(zones);
      return { lat: center.latitude, lng: center.longitude };
    },
    centerMap() {
      let zones = [];
      this.zones.forEach((zone) => {
        let z = zone.paths.map((z) => ({ latitude: z.lat, longitude: z.lng }));
        zones.push(...z);
      });

      let center = getCenter(zones);

      if (center) this.point = { lat: center.latitude, lng: center.longitude };
    },
    getClass(zone) {
      let classes = [];
      if (this.zones.length <= 9) classes.push("col-md-4 col-12");
      if (zone.color == "green") classes.push("bg-success text-white");
      if (zone.color == "blue") classes.push("bg-primary text-white");
      if (zone.color == "black") classes.push("bg-dark text-white");
      if (zone.color == "orange") classes.push("bg-warning text-white");
      if (zone.color == "red") classes.push("bg-danger text-white");
      if (zone.color == "yellow") classes.push("bg-yellow text-dark");
      if (zone.color == "purple") classes.push("bg-purple text-white");
      if (zone.color == "pink") classes.push("bg-pink text-dark");
      return classes;
    },
  },
  computed: {
    getZones() {
      return this.zones.filter((z) => z.component !== "banned");
    },
    zones() {
      let data = [];
      if (this.info && this.info.settings && this.info.settings.zones)
        data = this.info.settings.zones;
      return data;
    },
    info() {
      return this.$store.state.info;
    },
  },
  watch: {
    info() {
      this.centerMap();
    },
  },
};
</script>

<style scoped>
.bg-yellow {
  background-color: #fffa65;
}
.bg-purple {
  background-color: #910dab;
}
.bg-pink {
  background-color: hsl(316, 100%, 81%);
}
</style>